// import React, { useState } from "react";
import React, {useState, useRef, useEffect} from 'react';
import { Link } from 'react-router-dom'; 
// import Switch from "../button/Switch";
import logo from './../../assets/logo-grey.png';
import './nav.scss';
import collaboration from './../../assets/img-collaboration.jpg';
import ShapeBtn from './../../components/button/ShapeBtn';
import {RiArrowDownSLine} from 'react-icons/ri';
import useOutsideClick from './../../helpers/useOutsideClick';
import BlogData from './../../data/blog.json';
import useAnalyticsEventTracker from './../../helpers/useAnalyticsEventTracker';

function Nav(props) {
  const ref = useRef();
  const [showResources, setShowResources] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState(false);
  const [resourceNavOpen, setResourceNavOpen] = useState(false);
  let {featured, list} = BlogData.blog;
  const gaEventTracker = useAnalyticsEventTracker('Navigation');

  useOutsideClick(ref, () => {
    setShowResources(false);
    if (!isOpen && window.pageYOffset < 5){
      setColor(false);
    }
  });

  const setNavColor = () => {
    if(!isOpen && !color){
      setColor(true);
    }
    if(color & window.pageYOffset < 5 && !isOpen){
      setColor(false);
    }
   
  }

  useEffect(() => {
    if (window.screen.height < 768){
      if(isOpen){
        document.body.style.overflow = 'hidden';
      }else{
        document.body.style.overflow = 'unset';
      }
    }
    
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>{
        if (window.pageYOffset > 5){
          setColor(true)
        }
        if (window.pageYOffset < 5){
          setColor(false)
          if (showResources && color){
            setColor(true)
          }
          if (!showResources && color){
            setColor(false)
          }
         
        }
        
      }
      );
    }
  });

  return (
    <>
    <header  className={`navigation ${showResources ? "on" : "off"} ${color ? "white" : "transparent"}`}>
          <div className="container">

            <div className='header-container'>

              <div className="logo-container">
                <Link to="/" onClick={()=>{gaEventTracker('Navigate to Home','Nav Link')}} className="item">
                  <img src={logo} className="logo" alt="Logo" />
                </Link>
              </div>

              <div className="navigation-sub right">                            
                <Link onClick={()=>{gaEventTracker('Navigate to Contact Sales Desktop','Nav Link')}} to="/contact-sales" className="item button">Contact Sales</Link>
                {/* <Switch
                  isOn={value}
                  onColor="#06D6A0"
                  handleToggle={() => setValue(!value)}
                /> */}
              </div>

              <div
                onClick={() => {setIsOpen(!isOpen);gaEventTracker('Hamburger Menu Click','Button');}}
                className={`hamburger ${isOpen ? 'open' : ''}`}>
                <svg className="ham hamRotate ham1" viewBox="0 0 100 100" width="50">
                <path
                      className="line top"
                      d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
                <path
                      className="line middle"
                      d="m 30,50 h 40" />
                <path
                      className="line bottom"
                      d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
              </svg>
              </div>

            </div>
            
          </div>
          
      </header>

      <nav className={`mobile-menu ${isOpen ? 'open' : ''}`}>
        <div className="container">
          <Link onClick={() => {setIsOpen(false);gaEventTracker('Navigate to Contact Sales Mobile','Nav Link');}} to="/contact-sales" className="item button">Contact Sales</Link>
        </div>
      </nav>


    </>
    
  )
}
 
export default Nav;