import React from "react";
import List from './../content/List';
// import EmailCapture from './../content/EmailCapture';
import logo from './../../assets/logo-grey.png';
// import androidBadge from './../../assets/android-store.png';
// import iosBadge from './../../assets/apple-store.png';
import FooterData from './../../data/footer.json';
import { TiSocialTwitter, TiSocialFacebook, TiSocialInstagram } from 'react-icons/ti';
import './footer.scss';
import useAnalyticsEventTracker from './../../helpers/useAnalyticsEventTracker';

function Footer(props) {
  const gaEventTracker = useAnalyticsEventTracker('Footer');
  let {column1, column2} = FooterData.footer;

  return (
    <footer className="footer-container">

      <div className="line"></div>
      <div className="container">
        <section className="row upper">
          <div className="column1 col-xs-12 col-sm-4">
            <img src={logo} className="logo" alt="Logo" />
            <h5>{column1.headline}</h5>
            <p>{column1.text}</p>
            {/* <EmailCapture 
              eyebrow={column1.emailEyebrow}
              placeholder={column1.emailPlaceholer}
              ctaText={column1.emailSubmitText}
              ctaUrl={column1.emailSubmitUrl} /> */}
            {/* <div className="row badge-container">
              <div className="col-xs-12 col-sm-6">
                  <a href=""><img src={iosBadge} alt="apple badge"/></a>
              </div>
              <div className="col-xs-12 col-sm-6">
                  <a href=""><img src={androidBadge} alt="android badge"/></a>
              </div>
            </div> */}
          </div>
          <div className="column2 col-xs-12 col-sm-8">
            <div className="row">
              {column2.map((item, i) => {
                  let {title} = item;
                  let arr = [];
                  item.menuitem.map((subitem, i) => {
                    return arr.push(subitem.item)
                  })
                  return <List key={i} title={title} gaCategory={'Footer'} list={arr}/>
                })
              }
            </div>
          </div>
        </section>

        <div className="lower">
          <div>Copyright © {new Date().getFullYear()} {FooterData.footer.companyName}</div>
        </div>

      </div>
    </footer>
  )
}
 
export default Footer;