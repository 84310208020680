import React, {useRef, useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import Intro from './../components/content/Intro';
import Card from './../components/content/Card';
// import LinkCard from './../components/button/LinkCard';
import ShapeBtn from '../components/button/ShapeBtn';
// import phone from '../assets/phone-profile.png';
import faces from '../assets/faces.png';
import downArrow from '../assets/down-arrow.png';
import imac from '../assets/imac.png';
import android from '../assets/android.png';
import ios from '../assets/ios.png';
import phoneMain from '../assets/main-page-phone.png';
import culture from '../assets/culture.jpg';
import social from '../assets/social.png';
import hrsupport from '../assets/hr-support.png';
import circleArrow from '../assets/circleArrow.svg';
import './../css/home.scss';
import useAnalyticsEventTracker from './../helpers/useAnalyticsEventTracker';
import useAnalyticsScrollEvent from './../helpers/useAnalyticsScrollEvent';
// import { TiSocialTwitter, TiSocialFacebook, TiSocialInstagram } from 'react-icons/ti';
// import Text from './../data/lorem.json';

 
function Home(props) {
  const gaEventTracker = useAnalyticsEventTracker('Home');
  useAnalyticsScrollEvent('Home');
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();// run this function from an event handler or an effect to execute scroll 

  const [prompt, setPrompt] = useState(null);

  useEffect(() => {
    const handler = (event) => {
      setPrompt(event);
    }
    window.addEventListener('beforeinstallprompt', handler)

    return () => {
      window.removeEventListener('beforeinstallprompt', handler)
    }
  }, [])

  const handleAddToHomeScreenClick = async () => {
    try {
      if (prompt){
        await prompt.prompt()
        prompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('The app was added to the home screen')
          } else {
            console.log('The app was not added to the home screen')
          }
        })
      }
    } catch (err){
      console.error('Error installing Web Platform',err)
    }
  }
  

  return (
    <>


      <section className="white home-hero">
        <div className="container row">
          <div className="col-xs-12 col-sm-7 col-md-7">

            <div className="intro-container">
              <div className="pill">Enterprise Social Platform</div>
              <div className="title-container">
                <h1 className="title-1"><span className="red">Friends</span> at work</h1>
                <span className="subtitle">and solve <span className="red">employee retention</span></span>
              </div>
              <div className="desc-container">
                Meetworkplace is an employee experience app designed to help create deeper and meaningful social interactions at work 
              </div>
              <div className="cta-container">
              <Link to="/contact-sales" onClick={()=>{gaEventTracker('Navigate to Contact Sales','Button')}} className="button">Contact sales</Link>
              {/* <Link to="/" onClick={()=>{executeScroll(); gaEventTracker('Navigate to Home learn more','CTA')}} className="naked-btn"><span>Learn more about us</span> <span><img src={downArrow}/></span></Link> */}
              <Link to="/why-meetapp" onClick={()=>{gaEventTracker('Navigate to Why Meetapp - Learn More','CTA')}} className="naked-btn"><span>Learn more</span></Link>
                <div className="col col-xs-4"></div>
                <div className="col col-xs-4"><span></span></div>
              </div>
              <div className="platform-title">Platform available:</div>
              <div className="badge-container">
              <div data-tooltip="Later release" className="tooltip"><button onClick={()=>{gaEventTracker('Later release - Android','Button')}}><img src={android}/></button></div>
              <div data-tooltip="Coming soon" className="tooltip"><button onClick={()=>{gaEventTracker('Coming soon - iOS','Button')}}><img src={ios}/></button></div>
              <div data-tooltip="Install now" className="tooltip"><button onClick={()=>{gaEventTracker('Install Now - PWA','Button'); handleAddToHomeScreenClick()}}><img src={imac}/></button></div>
              </div>
            </div>

          </div>
          <div className="col-xs-12 col-sm-5 col-md-5">
              <img src={faces}/>
          </div>
        </div>
      </section>



    

      <section ref={myRef} className="bg-grey">
        <div className="container small">
          <div className="intro-container center">

              <div className="title-container">
                <h2 className="title-2">Why Meetworkplace?</h2>
              </div>
              <div className="desc-container">
                Meetworkplace offers employees an AI interest-based matchmaking technology allowing employees to quickly make new friends at work.
              </div>
              
            </div>
        </div>
      </section>


      
    

     
    </>
  )
}
 
export default Home;